html,
body {
	font-size: 16px;
	height: 100%;
	margin: 0;
}

body {
	background: black;
	overflow-y: scroll;
	font-family: 'protofont',Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important;
}

#root {
	position: relative;
	height: 100%;
	margin: 0;
}

a {
	cursor: pointer;
}

.hero {
	padding-top: 1.5rem;
	margin-bottom: 1.5rem;
}

.hero .logo-long {
	max-width: 100%;
	height: 2rem;
	display: block;
	margin: auto;
}

.hero a {
	outline: none;
}

#ps-menu .menu .item {
	padding: 1.25rem 1rem !important;
}

#ps-menu .dropdown.icon {
	display: none;
}

.topPadding {
	padding-top: 1rem;
}

.topPadding > .ui.container {
	min-height: 45rem;
}

.bottomMargin {
	margin-bottom: 1rem;
}

.photo-404 {
	max-width: 100%;
	height: auto;
}

.swordfish {
	width: 100%;
	margin-top: -1rem;
}

.content-wrap {
	min-height: 100%;
	background: #fafafa;
}

.content-wrap-inside {
	padding-bottom: 24rem;
}

.course-editor,
.class-editor,
.transaction-editor {
	margin-bottom: 1rem;
}

.ql-container {
	height: 30rem !important;
	font-size: inherit !important;
	font-family: inherit !important;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
	margin-bottom: 1rem !important;
}

.rdtTimeToggle {
	font-size: 2rem;
	padding-top: 1rem;
}

.ui.floating.dropdown.button.icon {
	border-left: 1px;
	border-left-color: #c0c1c2;
	border-left-style: solid;
}

.attendance-row {
	margin-top: 1rem;
}

.attendance-row .button {
	margin-bottom: 4px;
}

.pay-custom {
	margin-bottom: 1rem;
	width: 10rem;
}

.bio-paragraph {
	white-space: pre-line;
	margin-top: -1rem;
	margin-left: 1rem;
}

.list-num {
	display: flex;
	align-items: center;
	margin-right: 1rem;
	color: black;
}

.chartstats .ui.statistic {
	margin: 0 1.5em 1em !important;
}

.coursetags .ui.tag.label {
	margin-bottom: 1rem;
}

.newclasstable {
	margin: 0 -0.5rem 0 -0.5rem;
	display: flex;
	flex-wrap: wrap;
}

.newclasstable .byline {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.newclasstable .byline .interest {
	display: inline;
	margin-left: auto;
}

.adminvetting .ui.button {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.interest .ui.button {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.interest .nonbutton {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	font-size: 0.85714286rem;
	display: inline-block;
	min-height: 1em;
	font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
	margin: 0 .25em 0 0;
	padding-top: 0.785714em;
	padding-bottom: 0.785714em;
}

.ui.button.storage-button {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	margin-top: 0.25rem;
}

.ui.tag.label {
	padding-left: 1rem;
	padding-right: 0.5rem;
}

.labelbox {
	display: inline;
	padding: 0.55rem 0.1rem;
	border: 2px solid transparent;
}

.labelbox-selected {
	display: inline;
	padding: 0.55rem 0.1rem;
	border: 2px solid black;
}

.display {
	display: flex;
	justify-content: space-between;
	height: 100vh;
	background-color: black;
	color: white;
	font-size: 1.75em;
}

.display-usage {
	border: 1px solid white;
	padding: 0.5em;
	align-self: flex-end;
	width: 25vw;
	height: 75vh;
}

.display-graphs {
	border: 1px solid white;
	padding: 0em;
	align-self: flex-end;
	width: 25vw;
	height: 75vh;
}

.display-classes {
	border: 1px solid white;
	padding: 0.5em;
	align-self: flex-end;
	width: 50vw;
	height: 75vh;
}

.display-classes p {
	font-size: 1.5em;
	text-align: right;
}

.display .display-classes .today,
.display .display-classes .today .ui.header{
	color: #4baafe !important;
}

.display-scores {
	border: 1px solid white;
	padding: 0.5em;
	align-self: flex-end;
	width: 25vw;
	height: 75vh;
}

.display-scores p {
	font-size: 1.5em;
	text-align: right;
}

.display .display-scores .toast {
	width: 40px;
	height: 40px;
	margin-bottom: 15px;
}

.usage {
	height: 100vh;
	background-color: black;
	color: white;
	padding: 0.5em;
	font-size: 2.5em;
}

.display .ui.header,
.usage .ui.header {
	color: white;
	margin-top: 0.5em;
	margin-bottom: 0em;
}

.display .stat,
.usage .stat {
	font-size: 2em;
	margin-bottom: 0;
}

.display-printers {
	border: 1px solid white;
	padding: 0;
	align-self: flex-start;
	width: 50vw;
	height: 100vh;
}

.display .printer-pic {
	width: 100%;
}

.nowrap-stat {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.filter-option {
	margin-right: 1rem;
}


.footer {
	margin-top: -20rem;
	background: black;
	color: white;
	display: grid;
}

@media (min-width: 650px) {
	.footer-content {
		transition: all 0.2s ease-in;
		opacity: 0.5;
	}

	.footer-content:hover {
		opacity: 1;
	}
}

/* grid bottom */
.footer canvas {
	grid-row: 1/2;
	grid-column: 1/2;
	margin: auto;
}

/* grid top */
.footer .container {
	z-index: 2;
	grid-row: 1/2;
	grid-column: 1/2;
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.footer p {
	color: white;
}

.footer .logo {
	filter: invert(100%);
	height: 2rem;
}

.footer a {
	color: white;
	outline: 0;
}

.footer .text a {
	border-bottom: 1px solid #ddd;
	padding-bottom: 2px;
}

.footer i {
	margin-top: 3px;
	margin-left: -3.5px;
	margin-right: 0.5em;
}

.ui.dropdown .menu .item .text {
	line-height: 1.5rem;
}

.links-menu {
	line-height: 1.75rem;
}

.protocoin-send.field {
	display: flex;
}

.protocoin-send.field > button {
	align-self: end;
}

.ui.segment,
.ui.menu .dropdown.item .menu,
.ui.dropdown .menu,
.ui.input,
.ui.input.focus > input,
.ui.input > input:focus,
.ui.input.focus > input,
.ui.input > input,
.ui.selection.dropdown,
.ui.form input,
.ui.form textarea,
.rdtPicker,
.ui.popup,
.ui.popup::before,
.recharts-wrapper .recharts-default-tooltip,
.ui.labeled.input > .label,
.ql-snow .ql-picker-label,
.ql-snow .ql-picker-options,
.ql-snow .ql-tooltip,
.ql-tooltip .ql-editing > input,
.ui.menu {
	background-color: #fafafa !important;
}

