.dark .content-wrap {
	background-color: #1a1a1a;
	color: white;
}

.dark .ui.button,
.dark img {
	filter: brightness(80%);
}

.dark a {
	color: #55aaff;
}

.dark .footer a {
	color: white;
}

.dark a:hover {
	color: #1e70bf;
}

.dark .footer a:hover {
	color: #aaa;
	border-bottom: 1px solid #666;
}

.dark .logo-long,
.dark .swordfish {
	filter: invert(1) brightness(80%);
}

.dark .ql-snow .ql-stroke {
	stroke: white;
}

.dark .recharts-text.recharts-label > tspan {
	fill: white;
}

.dark .labelbox-selected {
	display: inline;
	padding: 0.55rem 0.1rem;
	border: 2px solid white;
}

.dark .ui.segment,
.dark .ui.menu .dropdown.item .menu,
.dark .ui.dropdown .menu,
.dark .ui.menu {
	background-color: #1a1a1a !important;
}

.dark .ui.table,
.dark .ui.table thead th,
.dark .ui.menu .item,
.dark .ui.menu .ui.dropdown .menu > .item,
.dark .ui.dropdown .menu > .item,
.dark .ui.form .field > label,
.dark .ui.items > .item > .content > .description,
.dark .ui.items > .item > .content > .header,
.dark .ui.checkbox label,
.dark .ui.selection.visible.dropdown > .text:not(.default),
.dark .ui.statistic > .label,
.dark .ui.statistics .statistic > .label,
.dark .ui.black.statistic > .value,
.dark .ui.black.statistics .statistic > .value,
.dark .ui.statistics .black.statistic > .value,
.dark i.black.icon,
.dark .list-num,
.dark .ui.header {
	color: white !important;
}

.dark .ui.menu,
.dark .ui.dropdown .menu,
.dark .ui.menu:not(.secondary):not(.text):not(.tabular):not(.borderless) > .container > .item:not(.right):not(.borderless):first-child,
.dark .ui.table tbody tr,
.dark .ui.segment {
	border-color: #444 !important;
}

.dark .ui.divided.items > .item,
.dark .ui.table tr td {
	border-top-color: #444 !important;
}

.dark .rdtPicker td.rdtDay:hover,
.dark .rdtPicker td.rdtHour:hover,
.dark .rdtPicker td.rdtMinute:hover,
.dark .rdtPicker td.rdtSecond:hover,
.dark .rdtPicker .rdtTimeToggle:hover,
.dark .rdtMonth:hover,
.dark td.rdtYear:hover,
.dark .rdtPicker thead tr:first-child th:hover,
.dark .rdtCounter .rdtBtn:hover,
.dark .ui.menu .item::before {
	background-color: #555 !important;
}

.dark .ql-snow .ql-editor code,
.dark .ql-snow .ql-editor pre,
.dark .ui.table td.active,
.dark .ui.table tr.active {
	color: white !important;
	background-color: #555 !important;
}

.dark .ui.input,
.dark .ui.input.focus > input,
.dark .ui.input > input:focus,
.dark .ui.input.focus > input,
.dark .ui.input > input,
.dark .ui.selection.dropdown,
.dark .ui.dropdown .menu .selected.item,
.dark .ui.dropdown.selected,
.dark .ui.selection.dropdown .menu > .item,
.dark .ui.form input,
.dark .ui.form textarea,
.dark .ui.menu .ui.dropdown .menu > .item:hover,
.dark .rdtPicker,
.dark .ui.popup,
.dark .ui.popup::before,
.dark .recharts-wrapper .recharts-default-tooltip,
.dark .ui.labeled.input > .label,
.dark .ql-snow .ql-picker-label,
.dark .ql-snow .ql-picker-options,
.dark .ql-snow .ql-tooltip,
.dark .ql-tooltip .ql-editing > input,
.dark .ui.menu .item:hover {
	color: white !important;
	background-color: #222 !important;
	border-color: #555 !important;
}

.dark .ui.popup::before {
	-webkit-box-shadow: 1px 1px 0 0 #555 !important;
	box-shadow: 1px 1px 0 0 #555 !important;
}

.dark .ui.black.labels .label:hover,
.dark a.ui.black.label:hover,
.dark .ui.black.label,
.dark .ui.black.labels .label {
	color: black !important;
	background-color: #fff !important;
	border-color: #fff !important;
}

.dark .ui.message,
.dark .ui.warning.message,
.dark .ui.info.message,
.dark .ui.error.message {
	box-shadow: 0 0 0 1px #707070 inset,0 0 0 0 transparent;
}

.dark .ui.warning.message .header,
.dark .ui.warning.message {
	background-color: #392400;
	color: #fffaf3;
}

.dark .ui.message .header,
.dark .ui.message,
.dark .ui.info.message .header,
.dark .ui.info.message {
	background-color: #003243;
	color: #f8ffff;
}

.dark .ui.form .field.error .ui.dropdown,
.dark .ui.form .field.error .ui.dropdown .item,
.dark .ui.form .field.error .ui.dropdown .text,
.dark .ui.form .fields.error .field .ui.dropdown,
.dark .ui.form .fields.error .field .ui.dropdown .item,
.dark .ui.form .field .prompt.label,
.dark .ui.error.message .header,
.dark .ui.error.message {
	background-color: #380100 !important;
	color: #fff6f6 !important;
}

.dark .ui.form .field.error .ui.dropdown .menu .active.item,
.dark .ui.form .fields.error .field .ui.dropdown .menu .active.item {
	background-color: #5d0000 !important;
}

